function ChapterTwo() {
  return (
    <>
      <p>
        It just so happened that I came across a video game, which became
        globally infamous for being very challenging and notoriously
        difficult to complete. Youtube was full of raging gamers, keyboards
        without keys, and flying coffee cups, and I thought &mdash; this is
        just too much ... challenge accepted! And so after many hours of
        infuriatingly frustrating but incredibly rewarding experience, the
        game was finally completed. However, the real takeaway from this
        experience was that there is a significant return on investment into
        tackling challenges, if persistence is strong enough. It was a
        first-hand demonstration that my sense of accomplishment was directly
        proportional to the degree of complexity of a given problem, and that
        opened new ways of thinking for me in approaching challenges, and
        specifically, taking up programming as a type of a challenge. That
        is what got me back to coding, although this time armed with a new
        mindset, and rather than rushing back to C++, I started small, with
        HTML, CSS and JavaScript.
      </p>
      <p>
        Shortly after, I was hired by an e-learning company, Interactive Pro,
        to manage the online learning platform and a set of products for a
        client. The front-end skills became instrumental in delivering series
        of enhancements to user experience that helped alleviate the customer
        rating scores beyond 90%. I worked with a team of engineers to implement
        an automated transcription service for video lectures that improved the
        accessibility, providing a more inclusive learner experience, which,
        over the recent years, became a hot topic of major significance in an
        online education industry. That is when we also integrated a popular web
        conferencing solution (Zoom) into the platform, enabling faculty and
        learners to interact in real time during seminars, workshops and
        collaboration sessions. This undertaking made online education persona 
        gain, and was received with a lot of praise from the client. Little had
        we known at that time a &quot;zoom fatigue&quot; would become a thing
        just a couple of years down the road.
      </p>
    </>
  );
}

export default ChapterTwo;