// libraries
import { useState } from "react";

// components
import Hero from "../../components/hero/hero";
import Footer from "../../components/footer/footer";
import Modal from "../../components/modal/modal";
import Colophon from "../../components/modal/colophon";

// assets
import ListasAppImage from "../../assets/images/siteimg-listas-app.png";
import BreakOutImage from "../../assets/images/siteimg-breakout-app.png";
import MusicPlayerImage from "../../assets/images/siteimg-muziqly-app.png";
import Lakeside from "../../assets/images/vectors/island-2.svg";

// styles
import "./projects.styles.css";

function Projects() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function setModal() {
    setIsModalOpen(!isModalOpen);
  }

  return (
    <>
      <Hero title="Playground" vector={Lakeside} />
      <main>
        <div className="presentation animateFall">
          <h1>Side Projects</h1>
          <p>
            A collection of some of my projects that I like to work on in my spare time. From fun little exercises to keep my development skills in shape, to full stack applications aiming to make life easier.
          </p>
          <hr />
          {/* PROJECT */}
          <article className="project-container mt-md">
            <div className="project-container__image mb-md">
              <img src={ListasAppImage} alt="project screenshot" />
            </div>
            <h2>Listas &mdash; grocery shopping organizer</h2>
            <p>The idea for building this application was initiated by my fiancée. Each time we would go shopping, we would dilligently jot down the shopping list on a piece of paper, to make sure we did not forget anything. However, often we'd find that upon arriving at the grocery store, the shopping list was not there with us.</p>
            <p>
              This was an exercise to build a full stack CRUD application with front-end, back-end, as well storage components. Its purpose was to enable users to create, and manage their shopping lists on both desktop and mobile browsers, as we were less likely to forget our smartphones at home when going out.
            </p>
            <p>
              The front-end was built using vanilla JavaScript, while the back-end is handled with PHP with server-side authentication. The database used on this project is MySQL
            </p>
            <a className="cta-link cta-action"
              href="https://listasapp.vladislavderetic.com"
              target="_blank">
              View project
              <span className="material-symbols-outlined light">
                arrow_outward
              </span>
            </a>
          </article>
          {/* PROJECT */}
          <article className="project-container mt-l">
            <div className="project-container__image mb-md">
              <img src={BreakOutImage} alt="project screenshot" />
            </div>
            <h2>Breakout &mdash; remake of an old classic</h2>
            <p>
              Playing video games was my passion when I was growing up. When I started learning to code, I set myself up with a challenge to develop at least one video game. I decided to start by building a remake of an all time classic, the Breakout.
            </p>
            <p>
              This project was built entirely using vanilla JavaScript, leveraging the browser's Canvas API for the manupulation of graphics. It was an excellent opportunity to get introduced to a different way of thinking when it comes to programming, but also concepts such as collision detection, the game loop and other aspects that are unique to video games.
            </p>
            <p></p>
            <a className="cta-link cta-action"
              href="https://vladislavderetic.com/apps/breakout"
              target="_blank">
              View project
              <span className="material-symbols-outlined light">
                arrow_outward
              </span>
            </a>
          </article>
          {/* PROJECT */}
          <article className="project-container mt-l">
            <div className="project-container__image mb-md">
              <img src={MusicPlayerImage} alt="project screenshot" />
            </div>
            <h2>Muziqly &mdash; a small music player</h2>
            <p>A fun little project that goes back to the beginnings of my programming journey. It was one of those classic projects aimed at practicing essential skills in font-end development. </p>
            <p>I wanted to take the project a little further than just a music player. It also included playlists, search function for tracks, and some fun effects. To build this, I needed to become comfortable with HTML, CSS, JavaScript, but also event handling, browser API for manipulating audio, and browser storage</p>
            <a className="cta-link cta-action"
              href="https://vladislavderetic.com/apps/music_player"
              target="_blank">
              View project
              <span className="material-symbols-outlined light">
                arrow_outward
              </span>
            </a>
          </article>
        </div>        
      </main>
      <Footer setModalFunction={setModal} />
      {isModalOpen && (
        <Modal 
          setModalFunction={setModal}
          content={Colophon}
        />
      )}
    </>
  );
}

export default Projects;