// libraries
import { useNavigate } from "react-router-dom";

// components
import LetterBrick from "../../components/letterbrick/letterbrick";

// styles
import "./header.styles.css";

// header assets
import MountainPass from "../../assets/images/vectors/island-1.svg";
import Lakeside from "../../assets/images/vectors/island-2.svg";
import ToscanyHills from "../../assets/images/vectors/island-3.svg";
import Cloud from "../../assets/images/vectors/cloud.svg";
import Stars from "../../assets/images/vectors/stars.svg";
import PineTreesFront from "../../assets/images/vectors/pine-trees-front.svg";
import PineTreesBack from "../../assets/images/vectors/pine-trees-back.svg";

function HomeHeader() {
  const navigate = useNavigate();

  return (
    <header className="hero__home">
      <img src={Stars} alt="vector image of stars" />
      <LetterBrick text={[
        "Welcome", "to", "my", "World"
      ]} />
      <figure 
        onClick={() => navigate("/tools")}
        className="animateFall">
          <img className="vector-cloud" src={Cloud} alt="vector image of a cloud" />
          <img className="vector-island" src={MountainPass} alt="vector image of mountains" />
          <img className="vector-cloud" src={Cloud} alt="vector image of a cloud" />
          <div className="signpost">
            Spellbook
            <span className="material-symbols-outlined">arrow_downward_alt</span>
          </div>
      </figure>
      <figure 
        onClick={() => navigate("/projects")}
        className="animateFall">
          <img className="vector-cloud" src={Cloud} alt="vector image of a cloud" />
          <img className="vector-island" src={Lakeside} alt="vector image of lakeside with lighthouse" />
          <div className="signpost">
            Play
            <span className="material-symbols-outlined">arrow_downward_alt</span>
          </div>
      </figure>
      <figure 
        onClick={() => navigate("/about")}
        className="animateFall">
          <img className="vector-cloud" src={Cloud} alt="vector image of a cloud" />
          <img className="vector-island" src={ToscanyHills} alt="vector image of rural landscape" />
          <img className="vector-cloud" src={Cloud} alt="vector image of a cloud" />
          <div className="signpost">
            Who did this?
            <span className="material-symbols-outlined">arrow_downward_alt</span>
          </div>
      </figure>
      <img src={PineTreesFront} alt="pine trees in the front" />
      <img src={PineTreesBack} alt="pine trees in the back" />
    </header>
  );
}

export default HomeHeader;