// styles
import "./modal.styles.css";

function Modal({ setModalFunction, content: Content }) {
  
  function closeModal(e) {
    e.preventDefault();
    setModalFunction();
  }

  return (
    <>
      <div onClick={setModalFunction} className="modal-overlay animateFadeIn"></div>
      <Content closefn={closeModal} />
    </>
  );
}

export default Modal;