// libraries
import { useState } from 'react';

function ContactForm(props) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  // form submission alerts
  const [alert, setAlert] = useState(false);
  const [alertClass, setAlertClass] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  
  // handle form submission
  function handleFormSubmit(e) {    
    e.preventDefault();
    const form = e.currentTarget;    
    const formData = new FormData(form);

    // Have to host your PHP script on the server.
    // For Development, using XAMPP, with Apache on port 80
    const url = window.location.origin + "/scripts/process.php";

    fetch(url, {
      method: 'POST',
      body: formData
    })
    .then(resp => {
      return resp.json();
    })
    .then(data => {
      // Destructure JSON response into array
      const [msg, msgClass] = data;

      // update alert state
      setAlert(true);
      setAlertClass(msgClass);
      setAlertMessage(msg);

      setTimeout(function() {
        setAlert(false);
        setAlertClass('');
        setAlertMessage('');
      }, 3000);

      // Clear input fields if submit successful
      if (msgClass == 'success') {
        setName('');
        setEmail('');
        setMessage('');
      }
    })
    .catch(err => {
        console.error(err);
    });
  }  
  
  return (
    <section className="modal-dialogue">
      <a onClick={props.closefn}
        className="cta-link cta-social" href="#">
        <span className="material-symbols-outlined">close</span>
      </a>
      <h2>Drop me a line</h2>
      {alert && (
        <span className={`alert alert--${alertClass}`}>
        <span className="material-symbols-outlined">
          {alertClass === 'error' ? 'cancel' : 'check_circle'}
        </span>
          {alertMessage}
        </span>        
      )}      
      <form onSubmit={handleFormSubmit}>
        {/* when specfying a PHP file in "form action" attribute, the file has to be on a server (use Xampp, which uses Apache, and specify the port on which the server is running(). See above the FETCH URL */}
          <div className="modal-dialogue__item">
            <input
              onChange={(e) => setName(e.target.value)}
              type="text"
              name="name"
              placeholder="Name"
              value={name}
             />
          </div>
          <div className="modal-dialogue__item">
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              name="email"
              placeholder="Email"
              value={email}
            />
          </div>
          <div className="modal-dialogue__item">
            <textarea
              onChange={(e) => setMessage(e.target.value)}
              name="message"
              placeholder="Message"
              value={message}
            />
          </div>
          <input type="submit" className="cta-link cta-action" value="Send" />
      </form>
    </section>
  );
}

export default ContactForm;