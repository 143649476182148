// styles
import "./footer.styles.css";

function Footer(props) {

  function handleClick(e) {
    e.preventDefault();
    props.setModalFunction();
  }

  function scrollToTop(e) {
    e.preventDefault();
    window.scrollTo(
      {
        top: 0,
        left: 0,
        behavior: 'smooth'
      }
    )
  }

  return (
    <footer>
      <p>&copy; {new Date().getFullYear()} by Vladislav Deretić</p>
      <div className="footer-links">
        <a 
          onClick={handleClick}
          className="cta-link cta-social">
            <span className="material-symbols-outlined">article</span>
        </a>
        <a
          onClick={scrollToTop}
          className="cta-link cta-social">
            <span className="material-symbols-outlined">arrow_upward_alt</span>
        </a>
      </div>
    </footer>
  );
}

export default Footer;