import { Link } from "react-router-dom";

function NotFound() {
  return (
    <main>
      <h1>Uh Oh!</h1>
      <p>The page you were looking for does not exist</p>
      <p>You may have typed the wrong address or the page has moved elsewhere.</p>
      <p>For now,&nbsp;
        <Link className="notfound__link" to="/">return to home page</Link>
      </p>
    </main>
  );
}

export default NotFound;