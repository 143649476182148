function ChapterOne() {
  return (
    <>
      <p>
        Ever since my early days, I was hard set on the idea that my life
        should revolve around computers, but little had I known what a rocky
        road it would be. Back in high school, I never imagined that I would
        cross paths with the education industry during my professional
        career, because at that time, I was too busy playing video games.
      </p>
      <p>
        Later down the road, after graduating from university with a degree
        in economics, I realized that playing video games was not exactly
        going to pay the bills. But making them certainly could, and I became
        obsessed about developing a game of my own and especially the kind of
        knowledge it takes to achieve it. So I went in full of enthusiasm and
        got myself a book on C++, which, as it turned out soon enough, may
        have not been the happiest choice of my first programming language.
        Pulling that blade out of a rock delivered a proverbial cold shower
        to my game developer aspirations and programming in general.
        But, no matter how discouraging it felt at that time, I never truly
        gave up on the idea of learning to code and making my own game.
      </p>
      <p>But more on that in a moment...</p>
      <p>
        Meanwhile, I made my way into a number of jobs, from an event
        coordinator for a small non-profit organization, over to a database
        administrator at a UK-based education provider, where I quickly grew
        to leading my own team on a business expansion project into the
        U.S. market. I spent some time in the U.S. on client premises, where
        I developed a successful admissions framework for international
        student recruitment, and secured a near instant spike in sales volume.
        This was early 2016 and it was exactly that time, which marked a
        turning point in my career, but mainly an important shift in mindset
        that stays with me to this day. Ironically, a video game was to blame.
      </p>
    </>
  );
}

export default ChapterOne;