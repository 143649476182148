// libraries
import { BrowserRouter, Routes, Route } from "react-router-dom";

// components
import NavBar from "./components/navbar/navbar.component";
import Home from  "./pages/home/home";
import About from "./pages/about/about";
import Projects from "./pages/projects/projects";
import Tools from "./pages/tools/tools";
import NotFound from "./pages/notfound/notfound";

// styles
import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <NavBar />
      <section className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="projects" element={<Projects />} />
          <Route path="tools" element={<Tools />} />
          <Route path="*" element={<NotFound />}></Route>
        </Routes> 
      </section>
    </BrowserRouter>   
  );
}

export default App;