function ChapterThree() {
  return (
    <>
      <p>
        Moving forward, I made sure to keep my JavaScript skills in shape.
        That turned out quite useful in summer 2018. Since the online learning
        platform was an open-source project, it equipped me enough to be able
        to read and understand the codebase, and get a deeper insight into how
        the software really works. I started digging into the API documentation
        and the technologies powering the platform, which was a time well spent
        as it revealed how third party services can work with the software,
        especially since there was an increasing sentiment on developing solutions
        including payment gateways, student portals, student information systems,
        or mobile applications. This is when we have onboarded a renowned
        British newspaper, for whom I managed the technical implementation of a
        subscription-based learning portal for the readers. This project in
        particular introduced me to back-end development, and intrigued me enough
        to start tinkering with PHP in my spare time.
      </p>
      <p>
        I was also privileged to have worked with some wonderful people on an
        experimental project of bringing virtual reality to online education.
        This was a remarkable experience. We collaborated with a VR studio to
        develop a virtual reality module for Facebook&apos;s Oculus Go headsets.
        This module intended to help students practice their public speaking skills,
        and would capture metrics such as volume of the voice, tonality,
        pronunciation as well as eye contact, and produce an overall performance
        score with suggestions on areas that learners needed to improve on.
      </p>
      <p>
        And then, the COVID hit hard. With the unconventional conditions brought
        upon us by COVID, the online education industry exploded! We needed to
        adapt quickly and transition a lot of clients who delivered their programmes
        traditionally in classrooms over to an online mode of delivery. That is when
        I have progressed into overseeing the setup and maintenance of online
        learning platforms for the entire business group, spanning 20+ clients.
      </p>
    </>
  );
}

export default ChapterThree;