// libraries
import { useState } from "react";

// components
import Hero from "../../components/hero/hero";
import ImageDivider from "../../components/imagedivider/imagedivider";
import Intro from "./intro";
import ChapterOne from "./chapterone";
import ChapterTwo from "./chaptertwo";
import ChapterThree from "./chapterthree";

import Footer from "../../components/footer/footer";
import Modal from "../../components/modal/modal";
import Colophon from "../../components/modal/colophon";
import ContactForm from "../../components/modal/contactform";

// assets
import ToscanyHills from "../../assets/images/vectors/island-3.svg";
import Book from "../../assets/images/vectors/about/book-toscany-countryside.svg";
import Excalibur from "../../assets/images/vectors/about/sword-in-a-rock.svg";
import MountainCabin from "../../assets/images/vectors/about/mountain-cabin.svg";

import TechDatabase from "../../assets/images/vectors/about/tech-db.svg";
import TechAnalytics from "../../assets/images/vectors/about/tech-analytics.svg";
import TechCode from "../../assets/images/vectors/about/tech-code.svg";
import TechServer from "../../assets/images/vectors/about/tech-server.svg";

import SaintMichelPhoto from "../../assets/images/photos/about-msm-f.jpg";
import RondaPhoto from "../../assets/images/photos/about-r-s.jpg";
import SaintMaloPhoto from "../../assets/images/photos/about-sm-f.jpg";
import UvacPhoto from "../../assets/images/photos/about-u-s.jpg";

// styles
import "./about.styles.css";

function About() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMessageClicked, setIsMessageClicked] = useState(false);

  function setModal() {
    setIsModalOpen(!isModalOpen);
  }

  return (
    <>
      <Hero title="About" vector={ToscanyHills} />
      <main>
        <Intro
          setMsgClickState={() => setIsMessageClicked(!isMessageClicked)}
        />
        <ImageDivider vector={Book} descr="book in Toscan countryside"/>
        <ChapterOne />
        <ImageDivider vector={Excalibur} descr="sword in a rock"/>
        <ChapterTwo />
        <div className="flex-container">
          <ImageDivider vector={TechDatabase} topm={false} descr="some image" />
          <ImageDivider vector={TechAnalytics} topm={false} descr="some image" />
          <ImageDivider vector={TechCode} topm={false} descr="some image" />
          <ImageDivider vector={TechServer} topm={false} descr="some image" />
        </div>
        <ChapterThree />
        <ImageDivider vector={MountainCabin} descr="log cabin in the mountains"/>
        
        {/* TO DO: Hobbies */}
        <p>
          In my spare time, I like to work on side projects, which is a great way to keep learning new technologies but also maintain existing skills in good shape. However, it does involve sitting additional hours in front of the screen. To combat that with some fresh O<sub>2</sub>, I enjoy taking my mountain bike for a ride in one of the two neighboring forests, or playing tennis.
        </p>
        <p>
          I love the outdoors, so I do my best to spend some time travelling with my fiancée and discover new places, looking out for hiking trails, mountains, rivers, cute villages, while not shying away from busy towns and cities (that have a positive relationship with greenery).</p>
        <p>
          Back inside, I am also a huge fan of music, and when no one is around, I take enough time to listen to some earth shattering metal music, discovering new bands, and playing guitar. The relentless nature of that genre may also explain my love for horror films, but I do actively enjoy many other genres in both areas.
        </p>
        <h2>Some memorable places</h2>
        <div className="gallery__wrapper">
          <div className="gallery__frame">
            <img src={UvacPhoto} alt="photo of serbian countryside" />
          </div>
          <div className="gallery__frame">
            <img src={SaintMichelPhoto} alt="photo of french castle on the shore" />
          </div>
          <div className="gallery__frame">
            <img src={RondaPhoto} alt="photo of rural town in spain on the rocks" />
          </div>
          <div className="gallery__frame">
            <img src={SaintMaloPhoto} alt="photo of a path leading to french medieval town" />
          </div>
        </div>
      </main>
      <Footer setModalFunction={setModal} />
      {isModalOpen && (
        <Modal 
          setModalFunction={setModal}
          content={Colophon}
        />
      )}
      {isMessageClicked && (        
        <Modal
          setModalFunction={() => setIsMessageClicked(!isMessageClicked)}
          content={ContactForm}
        />
      )}
    </>
  );
}

export default About;