// libraries
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

// assets
import Logo from "../../assets/logos/logo.svg";

// styles
import "./navbar.styles.css";

function NavBar() {
  const [navbarIsOpaque, setNavbarIsOpaque] = useState(false);
  
  // function that listens to scroll event
  function listenToScroll() {    
    this.scrollY > 5 ? setNavbarIsOpaque(true) : setNavbarIsOpaque(false);
  }

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    // cleanup
    return () => window.removeEventListener('scroll', listenToScroll);
  });

  return (
    <nav className={navbarIsOpaque ? 'isScrolling' : ''}>
      <img src={Logo} alt="site logo" />
      <div className="navlinks-container">
        <NavLink onClick={() => window.scrollTo(0, 0)} to="/home">Home</NavLink>
        <NavLink onClick={() => window.scrollTo(0, 0)} to="/about">About</NavLink>
        <NavLink onClick={() => window.scrollTo(0, 0)} to="/projects">Playground</NavLink>
        <NavLink onClick={() => window.scrollTo(0, 0)} to="/tools">Tools</NavLink>
      </div>
    </nav>
  );
}

export default NavBar;