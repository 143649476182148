// styles
import "./itemcard.styles.css";

function ItemCard({ content }) {
  return (
    <div className={`fitem-container ${content.iconType === 'google' ? 'fribbon' : ''}`}>    
      {content.iconType === 'google' && (
        <div className="fitem-container__icon">
          <span className="material-symbols-outlined size-48 branded">
            {content.iconText}
          </span>
        </div>
      )}     
      {content.iconType !== 'google' && (
        <i className={content.iconText}></i>
      )}
      <span>
        {content.text}
      </span>
    </div>
  );
}

export default ItemCard;