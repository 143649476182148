// styles
import "./factsticker.styles.css";

function FactSticker(props) {
  return (
    <div className="facts__sticker">
      <div className="facts__icon">
        <img src={props.icon} alt="icon of gamepad" />
      </div>
      <div className="facts__text">{props.text}</div>
    </div>
  );
}

export default FactSticker;