// assets
import Cloud from "../../assets/images/vectors/cloud.svg";

// styles
import "./hero.styles.css";

function Hero(props) {
  return (
    <header>
      <h1 className="animateFall">{props.title}</h1>
      <figure className="animateFadeIn">
        <img className="hero-image" src={props.vector} alt="section image" />
      </figure>
      <img
        className="vector-cloud animateFall"
        src={Cloud}
        alt="vector image of a cloud"
      />
      <img
        className="vector-cloud animateFall"
        src={Cloud}
        alt="vector image of a cloud"
      />
    </header>
  );
}

export default Hero;